<template>
  <v-card flat>
    <v-container>
      <v-row justify="center">
      <v-card width="500"  flat>
      <v-row class="ma-auto">
          <v-col>
            <h4>{{ $t("Info") }}</h4>
            <table style="width:100%">
             
                <thead>
                  <tr>
                    <th >
                      Web UI version
                    </th>
                    <th >
                      Fulomatic version
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">{{$store.state?.packageVersion || "----"}}</td>

                    <td class="text-center">{{$store.state?.pumps?.fm_ver || "----"}}</td>
                  </tr>
                </tbody>
              
            </table>
          </v-col>
        </v-row>
        </v-card>
      </v-row>
      <v-row justify="center">
          <p class="text-h5"> {{$t('Main actions')}}</p>
        
        </v-row>
      <v-row class="mt-auto" justify="center">
          <v-col cols="12" md="3" align="center">
            <v-btn large color="vinland-lighten2" dark class="ma-auto" @click="proceedAction('restart')">{{ $t("Restart app") }}</v-btn>
          </v-col>
          <v-col v-if="$auth.user().roles === '1'" cols="12" md="3" align="center">
            <v-btn large color="vinland-lighten1" dark class="ma-auto" @click="proceedAction('reboot')">{{ $t("Reboot") }}</v-btn>
          </v-col>
          <v-col cols="12" md="3" align="center">
            <v-btn large color="#196619" dark class="ma-auto" @click="proceedAction('shutdown')">{{ $t("Shut Down") }}</v-btn>
          </v-col>
          <v-col v-if="$auth.user().roles === '1'" cols="12" md="3" align="center">
            <v-btn  large color="bloody" dark class="ma-auto" @click="proceedAction('cleantables')">{{ $t("Clean tables") }}</v-btn>
          </v-col>
      </v-row>
    </v-container>
     <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to proceed this <strong>{{action}} </strong> action?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="submitAction"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
      dialog: false,
      action: ''
    }
  },
  methods: {

    proceedAction(act) {
      this.action = act
      this.dialog = true
    },
    async submitAction() {
      this.dialog = false
      let res = null
       try {
          this.loading = true
          res = await this.$http.get(`/command/${this.action}`)
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading = false
        } finally {
          this.loading = false
          this.action = ''
        }
    }
  }

  
};
</script>

<style scoped>

.bloody--text {
  color: #db2828 !important;

}

.bloody {
  background-color: #db2828 !important;

}


.vinland-lighten1 {
  background-color: #259725 !important;
}
.vinland-lighten2 {
  background-color: #2bb82b !important;
}

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

</style>